<template>
  <div class="animated fadeIn">
    <b-btn variant="success" size="lg" v-on:click="createPromotion()">{{ $t("message.createPromotion") }}</b-btn>
    <br />
    <br />
    <b-card :title="$t('message.allPromotion')">
      <form v-on:submit.prevent="getPromotion">
        <div class="row">
          <div class="col-sm-6 col-md-6">
            {{ $t("message.machineIdFarm") }} :
            <br />
            <multi-list-select :list="machineData" option-value="machineId" option-text="name"
              :selected-items="selectedMachine" placeholder="All" @select="onSelectMachine"></multi-list-select>
          </div>

          <div class="col-sm-2 col-md-2">
            {{ $t("message.type") }}:
            <br />
            <b-form-select :options="promationType" text-field="name" value-field="value.typeId"
              v-model="searchData.typeId"></b-form-select>
          </div>

          <div class="col-sm-4 col-md-4">
            <br />
            <b-btn variant="primary" type="submit">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>
          </div>
        </div>
      </form>
      <br />
      <div class="table-responsive">
        <Table stripe row-key="transactionId" size="small" :columns="column" :data="promotions">
          <template slot-scope="{ row, index }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click="editPromotion(row)">
              <Icon type="md-cog" />
              {{ $t("message.setting") }}
            </Button>
            <Button type="error" size="small" @click="showModalPromotion(row)">{{ $t("message.delete") }}</Button>
          </template>
        </Table>
      </div>
      <br />
      <Page :total="totalRows" :page-size="itemperPage" @on-change="getPromotion" />
    </b-card>

    <b-modal ref="deletePromotion" title="ลบโปรโมชั่น" @ok="deletePromotion">
      <table class="table2">
        <tr>
          <td>{{ $t("message.machineIdFarm") }}</td>
          <td>{{ promotionData.machineId }}</td>
        </tr>
        <tr>
          <td>{{ $t("message.promotion") }}</td>
          <td>{{ promotionData.name }}</td>
        </tr>
        <tr>
          <td> {{ $t("message.period") }}</td>
          <td>
            {{ promotionData.time[0] | moment("DD-MM-YYYY") }} -
            {{ promotionData.time[1] | moment("DD-MM-YYYY") }}
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import webServices from '../../script'
import { MultiListSelect } from '../../custom_modules/search-select'
export default {
  name: 'EditPromotion',
  components: {
    MultiListSelect
  },
  data() {
    return {
      role: window.localStorage.getItem('roleID'),
      totalRows: 0,
      itemperPage: 20,
      currentPage: 1,
      searchData: {},
      machineData: [],
      selectedMachine: [],
      promationType: [],
      promotionData: {
        machineId: '',
        name: '',
        time: []
      },
      promotions: [],
      column: [
        {
          title: this.$t('message.machineIdFarm'),
          key: 'machineId',
          minWidth: 100,
          align: 'center'
        },
        {
          title: this.$t('message.promotion'),
          key: 'name',
          minWidth: 330,
          align: 'left'
        },
        {
          title: this.$t('message.period'),
          // key: 'time',
          minWidth: 300,
          align: 'center',
          render: (h, params) => {
            if (params.row.time) {
              if (params.row.time[0] != null && params.row.time[0] != 'Invalid date') {
                return h('span', `${moment(params.row.time[0]).format('YYYY-MM-DD HH:mm')} ~ ${moment(params.row.time[1]).format('YYYY-MM-DD HH:mm')}`)
                //  return h('span', params.row.time)
              }
            } else {
              return ''
            }
          }
        },
        {
          title: this.$t('message.deviceUsed'),
          // key: 'slots',
          minWidth: 170,
          align: 'left',
          render: (h, params) => {
            return h('span', params.row.slots.toString())
          }
        },
        {
          title: this.$t('message.priority'),
          key: 'priority',
          minWidth: 100,
          align: 'right'
        },
        {
          title: this.$t('message.createPromotionBy'),
          key: 'createByUserID',
          minWidth: 120,
          align: 'left',
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            return h(
              'Poptip',
              {
                props: {
                  trigger: 'hover',
                  content: params.row.createByUser.userName
                }
              },
              [h('span', params.row.createByUser.userName)]
            )
          }
        },
        {
          title: this.$t('message.editPromotionBy'),
          key: 'userName',
          minWidth: 120,
          align: 'left',
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            return h(
              'Poptip',
              {
                props: {
                  trigger: 'hover',
                  content: params.row.updateByUser.userName
                }
              },
              [h('span', params.row.updateByUser.userName)]
            )
          }
        },
        {
          title: this.$t('message.updatedAt'),
          key: 'users.updatedAt',
          minWidth: 170,
          align: 'center',
          render: (h, params) => {
            return h('span', moment(params.row.updatedAt).format('YYYY-MM-DD HH:mm'))
          }
        },
        {
          title: this.$t('message.detail'),
          slot: 'action',
          width: 150,
          align: 'center'
        }
      ]
    }
  },
  methods: {
    isAdmin(role) {
      return webServices.isAdmin(role)
    },
    getMachineList() {
      this.axios
        .get(`/machines/list?`)
        .then(res => {
          this.machineData = res.data
        })
        .catch(err => {
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: err
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    getPromotion(page = 1) {
      this.$Progress.start()

      this.currentPage = page

      var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      const params = {
        ...this.searchData,
        page,
        limit: this.itemperPage
      }

      this.axios
        .get(`/promotions`, { params })
        .then(res => {
          this.$Progress.finish()
          if (res.data) {
            this.promotions = res.data.rows
            this.totalRows = res.data.count
          }
          console.log('res', res.data)
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: err
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
    },
    showModalPromotion(promotion) {
      this.promotionData = promotion
      this.$refs.deletePromotion.show()
    },
    editPromotion(data) {
      this.$router.push({
        path: `/promotion/edit/${data.machineId}/${data.promotionId}`,
        params: { machineId: data.machineId, promotionId: data.promotionId }
      })
    },
    createPromotion() {
      this.$router.push('/promotion/create')
    },
    getPromotionType() {
      var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      const params = {
        ...this.searchData,
        type: 3
      }

      this.axios
        .get(`/promotions/type`, { params })
        .then(res => {
          if (res.data) {
            this.promationType = res.data
          }
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: err
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    deletePromotion() {
      var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.promotionData.userName = localStorageUser.userName
      this.promotionData.userRole = localStorageUser.role
      this.promotionData.userType = localStorageUser.type
      this.promotionData.userConfiglanguage = localStorageUser.config.language
      this.promotionData.datetimeRequest = datetimeRequest

      const { promotionId, machineId } = this.promotionData
      this.$Progress.start()

      const params = {
        ...this.promotionData,
        promotionId: promotionId,
        machineId: machineId
      }

      this.axios
        .delete(`/promotion`, { params })
        .then(res => {
          if (res.data) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'สำเร็จ'
            })
            this.getPromotion(this.currentPage)
          } else {
            this.$toast.error({
              title: 'ERROR',
              message: 'ไม่สามารถลบได้'
            })
          }
        })
        .catch(err => {
          this.$Progress.fail()
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: err
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    }
  },
  mounted() {
    this.getMachineList()
    this.getPromotion()
    this.getPromotionType()
  }
}
</script>
